import { FullPageElement, html, unsafeHTML, css, formCss } from 'Elements';
import { Fetcher, UrlParams } from 'Utils';
import App from 'App';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import config from './config.js';
import './detail.js';

class EventsTable extends FullPageElement {
  static get styles() {
    return [
      super.styles,
      formCss,
      css`
        table {
          width:100%;
          border-collapse: collapse;
          box-sizing: border-box;
          font-size:0.9em;
          text-align:left;
        }

        table.disabled {
          pointer-events:none;
          filter: grayscale(100%);
          opacity:0.5;
        }

        table thead {
          font-weight:bold;
          height:40px;
          align-items:center;
          background-color: var(--sl-color-neutral-100);
          box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 8px -2px;
          top:0px;
          position:sticky;
          z-index:10;
        }

        table th {
          border: 1px solid var(--sl-color-neutral-200);
          padding-left: 10px;
        }

        table tr {
          box-sizing: border-box;
          border-radius:5px;
        }

        table td {
          padding: 7px;
          border:1px solid var(--sl-color-neutral-200);
        }

        table td.right {
          padding-right:10px;
          text-align:right;
        }

        table td.icon {
          width:30px;
          text-align:center;
          font-size:1.8em;
          font-weight:bold;
          padding:0;
        }

        table td.icon m-icon {
          opacity:1;
          transition: none;
        }

        table tbody tr:nth-child(even) td {
          
        }

        table tbody tr:nth-child(odd) td {
          background-color: var(--table-list-odd-background-color)
        }

        table tbody tr.line {
          cursor:pointer;
        }

        table tbody tr.line:hover {
          outline:2px ridge var(--sl-color-primary-200);
          outline-offset: -1px;
        }

        table tbody tr.selected {
          outline:2px ridge var(--sl-color-primary-500);
          outline-offset: -1px;
        }

        table tbody tr:hover td.icon m-icon {
          opacity:1;
        }

        table tr {
          padding:0;
        }

        table td {
          padding:0px;
          padding-left: 7px;
          padding-bottom: 0px;
        }

        td.grouper {
          background-color: var(--sl-color-neutral-100) !important;
        }

        .group-header {
          background-color: var(--table-list-group-background-color);
          font-size: 1em;
          padding:5px;
          padding-right:10px;
          padding-left: 10px;
          color:white;
          text-transform: capitalize;
          display:inline-block;
          border-radius: 20px;
          width:200px;
          margin-top:20px;
          margin-bottom:20px;
          margin-left:0px;
        }

        .events {
          display:flex;
          flex-wrap:wrap;
          gap:5px;
          padding:5px;
        }

        .events m-icon {
          font-size:1.4em;
        }

        .events sl-tag::part(base) {
          padding-left:3px;
          font-size:0.9em;
        }

        .events sl-tag m-icon {
          margin-right:5px;
          font-size:1.7em;
          padding-left:0px;
        }

        .pagesnav {
          display:flex;
          justify-content:space-between;
          align-items:baseline;
          gap:10px;
          margin:5px;
          min-height:30px;
        }

        .pagesnav .title {
          font-size:0.9em;
        }

        .empty {
          display:flex;
          width:100;
          justify-content:center;
          text-align:center;
          gap:10px;
          padding:20px;
          flex-direction:column;
        }

        .empty m-icon {
          font-size:3em;
        }

        em {
          font-style: normal;
          background-color: var(--sl-color-yellow-200);
          padding-left:2px;
          padding-right:2px;
        }

        @media (max-width: 670px) {
          .header {
            flex-direction: column;
            gap: 10px;
          }
        }

      `
    ]
  }

  static get translations() {
    return [
      super.translations,
      config.translations,
      {
        english:{
          translation: {
            event:'event',
            date:'When ?',
            who:'Who ?',
            empty:'No event available',
          }
        },
        french:{
          translation: {
            event:'évènement',
            date:'Quand ?',
            who:'Qui ?',
            empty:'Aucun évènement disponible',
          },
        }
      }
    ]
  }

  static get properties() {
    return {
      items: { type: Array },
      groupBy: { type: String },
      newEvents: { type: Number },
    };
  }

  constructor() {
    super();
    this.apiEndpoint = config.apiEndpoint;
    this.urlVar = config.urlVar;
    this.items = null;
    this.page = parseInt(UrlParams.get('p', 1));
    this.displayFilter = false;
    this.onLanguageChanged = this.onLanguageChanged.bind(this);
  }
  
  connectedCallback() {
    super.connectedCallback();
    this.setDayJsLocale();
    window.addEventListener('language-changed', this.onLanguageChanged);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener('language-changed', this.onLanguageChanged);
  }

  setDayJsLocale() {
    dayjs.locale(App.config.lg);
  }

  async onLanguageChanged() {
    this.setDayJsLocale();
  }

  async visibleCallback() {
    super.visibleCallback();
    if (this.items === null) {
      await this.reloadData();
    }
  }

  renderPagesNav() {
    const totalCount = this.meta?.totalCount || 0;
    const totalText = totalCount >= 1 ? `${this._tl('event')}s` : this._tl('event');
    return html`
      <div class="pagesnav">
        <div class="title">${totalCount} ${totalText}</div>
        <data-paginator pages=${this.meta?.totalPages} value=${this.page} @change=${this.onPaginatorChange}></data-paginator>
      </div>
    `;
  }

  async reloadData(ev) {
    this.loading = true;
    
    this.loader = this.loader || this.parent.qs('sl-progress-bar');
    this.loader.style.visibility = 'visible';

    let url = this.apiEndpoint;
    const params = new URLSearchParams(window.location.search);
    const searchParams = Object.fromEntries(params.entries());
    const response = await Fetcher.post(url, searchParams);
    this.items = response?.data;
    this.meta = response?.meta;
    this.loading = false;
    this.loader.style.visibility = 'hidden';
    this.newEvents = 0;
  }

  async pushData(data) {
    if (this.loading) return;
    if (this.items) {
      this.items.unshift(data);
      if (this.items.length > 100) this.items.pop();
      this.requestUpdate();
      window.dispatchEvent(new CustomEvent('playsound', { detail:'notif' }));
    }
  }

  onPaginatorChange(ev) {
    this.page = ev.detail;
    UrlParams.set('p', this.page);
    this.reloadData();
  }

  groupEvents() {
    const grouped = {};

    this.items.forEach(event => {
      const createdDate = dayjs(event.date);
      let key;

      if (this.groupBy === 'day') {
        key = createdDate.format('dddd DD/MM/YYYY'); // Formate pour regrouper par jour
      } else if (this.groupBy === 'week') {
        const startOfTheWeek = createdDate.isoWeek(); // Numéro de la semaine ISO
        key = `Semaine ${startOfTheWeek}: ${createdDate.startOf('isoWeek').format('DD/MM')} - ${createdDate.endOf('isoWeek').format('DD/MM')}`;
      } else if (this.groupBy === 'month') {
        key = createdDate.format('MMMM YYYY'); // Regroupe par mois
      }

      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(event);
    });

    return grouped;
  }

  getColor(c) {
    return c?.includes('--') ? c : c + '-700';
  }

  renderColumnEvent(item, main = true) {
    if (!item.events?.length) return;

    const eventsToRender = main ? item.events.slice(0, 1) : item.events.slice(1);

    return html`
      <div class="events">
        ${eventsToRender.map(e => {
          const ev = config.events[e];
          if (ev) {
            if (main) {
              return html`<sl-tag size="small" variant="${ev.color}" title="${this._tl(`events.${e}`)}">
                <m-icon name="${ev.icon}" style="color:${ev.iconColor});"></m-icon>
                ${this._tl(`events.${e}`)}
              </sl-tag>`;
            } else {
              return html`<m-icon name="${ev.icon}" style="color:${ev.iconColor});" title="${this._tl(`events.${e}`)}"></m-icon>`;
            }
          } else {
            return html`<sl-tag size="small">${e}</sl-tag>`;
          }
        })}
      </div>
    `;
  }

  panelOpen(ev, item) {
    this.modalDetail = this.modalDetail || this.qs('sys-events-detail');
    UrlParams.set(this.urlVar, item.id);
    this.modalDetail.item = null;
    this.modalDetail.item = item;
  }

  panelClose(ev) {
    UrlParams.del(this.urlVar);
  }

  pick(from, s) {
    return s
        .replace(/\[([^\[\]]*)\]/g, ".$1.")
        .split(".")
        .filter((t) => t !== "")
        .reduce((prev, cur) => prev && prev[cur], from)
  }

  renderColumnInfo(item) {
    if (!item.events) return;

    const ev = item.events[0];
    if (!ev) return;

    const whoTemplate = config.events[ev]?.who;
    if (!whoTemplate) return;

    let who = whoTemplate;
    const matches = whoTemplate.match(/\${(.*?)}/g);
    for (let match of matches) {
      match = match.substring(2, match.length - 1);
      const value = this.pick(item, `highlight.${match}`) || this.pick(item, match);
      if (value) {
        who = who.replace('${'+match+'}', value);
      }
    }

    return who;
  }

  renderColumnDate(item) {
    if (this.groupBy === 'day') {
      return dayjs(item.date).format('HH:mm');
    } else {
      return dayjs(item.date).format('DD/MM/YYYY HH:mm');
    }
  }

  /*
  renderTableHeader() {
    return html`
      <thead>
        <tr>
          <th width="300">${this._tl('event')}</th>
          <th><!--${this._tl('who')}--></th>
          <th width="300" align="center"><!--${this._tl('date')}--></th>
        </tr>
      </thead>
    `;
  }
  */

  renderItems() {
    if (this.loading) {
      return '';
    }

    if (!this.items?.length) {
      return html`
        <div class="empty">
          <m-icon name="troubleshoot" size="big"></m-icon>
          <div>${this._tl('empty')}</div>
        </div>
      `;
    }

    const items = this.groupEvents();

    return html`
      <table ${this.loading ? 'disabled': ''}>
        <tbody>
        ${Object.keys(items).map(group => html`
          <tr>
            <td colspan="100" class="grouper">
              <div class="group-header">${group}</div>
            </td>
          </tr>
          ${items[group].map(item => html`
            <tr class="line" @click=${ev => this.panelOpen(ev, item)}>
              <td width="250">${this.renderColumnEvent(item, true)}</td>
              <td>${unsafeHTML(this.renderColumnInfo(item))}</td>
              <td class="right">${this.renderColumnDate(item)}</td>
            </tr>
          `)}
        `)}
        </tbody>
      </table>
    `;
    
    // <th>${this._tl('event_secondary')}</th>
    // <td>${this.renderColumnEvent(item, false)}</td>
  }

  render() {
    return html`
      ${this.renderPagesNav()}
      ${this.renderItems()}
      <sys-events-detail @close=${this.panelClose}></sys-events-detail>
    `;
  }
}

customElements.define('sys-events-table', EventsTable);
